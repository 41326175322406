import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/About',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/News/:id',
    name: 'news',
    component: () =>
      import(/* webpackChunkName: "news" */ '../views/NewsList.vue'),
  },
  {
    path: '/NewsPage/:id',
    name: 'newspage',
    component: () =>
      import(/* webpackChunkName: "newspage" */ '../views/NewsPage.vue'),
  },
  {
    path: '/Features',
    name: 'features',
    component: () =>
      import(/* webpackChunkName: "features" */ '../views/Features.vue'),
  },
  {
    path: '/DownloadCenter',
    name: 'download_center',
    component: () =>
      import(
        /* webpackChunkName: "download_center" */ '../views/DownloadCenter.vue'
      ),
  },
  {
    path: '/Contact',
    name: 'contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/error_page',
    name: 'error_page',
    component: () =>
      import(/* webpackChunkName: "error_page" */ '../views/ErrorPage.vue'),
  },
  {
    path: '*',
    redirect: '/error_page',
  },
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})
