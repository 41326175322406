<template>
  <div id="app">
    <nav id="index-nav">
      <div class="main-nav">
        <router-link to="/">
          <img
            :src="LogoImg"
            class="nav-logo"
            alt="鼎創工程"
            title="鼎創工程"
          />
        </router-link>
        <div class="nav-btns">
          <div class="menu-btns">
            <router-link
              to="/About"
              v-on:click.native="OpenMenu(0)"
              class="nav-btn"
              >公司簡介</router-link
            >
            <router-link
              to="/Features"
              v-on:click.native="OpenMenu(0)"
              class="nav-btn"
              >服務與設備</router-link
            >
            <router-link
              to="/News/1"
              v-on:click.native="OpenMenu(0)"
              class="nav-btn"
              >工程實績</router-link
            >
            <router-link
              to="/DownloadCenter"
              v-on:click.native="OpenMenu(0)"
              class="nav-btn"
              >線上資源</router-link
            >
            <!-- <span href="#" class="nav-btn dropdown">
              營業項目
              <ol>
                <li>
                  <router-link to="/Features" v-on:click.native="OpenMenu(0)">配方研擬</router-link>
                </li>
                <li>
                  <router-link to="/OEM" v-on:click.native="OpenMenu(0)">代工生產</router-link>
                </li>
                <li>
                  <router-link to="/BrandPlan" v-on:click.native="OpenMenu(0)">品牌策劃</router-link>
                </li>
              </ol>
            </span>-->
          </div>
          <a
            href="javascript:void(0)"
            v-on:click="OpenMenu(0)"
            class="nav-btn menu-btn"
          >
            <i style="margin-right: 10px" class="fas fa-bars"></i>MENU
          </a>

          <router-link to="/Contact" class="nav-btn special contact">
            <i class="fas fa-phone"></i>
            <p>聯絡我們</p>
          </router-link>
          <a
            class="nav-btn special social"
            href="http://monitor.dingchong.com.tw/"
          >
            <p>
              <span>自動化</span>
              <br />監測平台
            </p>
          </a>
        </div>
      </div>

      <a
        href="javascript:void(0)"
        v-on:click="OpenMenu(0)"
        class="nav-btn sm-menu-btn"
      >
        <i class="fas fa-bars"></i>
      </a>

      <div class="med-menu-btns">
        <router-link to="/About" v-on:click.native="OpenMenu(0)" class="nav-btn"
          >公司簡介</router-link
        >
        <router-link
          to="/Features"
          v-on:click.native="OpenMenu(0)"
          class="nav-btn"
          >服務與設備</router-link
        >
        <router-link
          to="/News/1"
          v-on:click.native="OpenMenu(0)"
          class="nav-btn"
          >工程實績</router-link
        >
        <router-link
          to="/DownloadCenter"
          v-on:click.native="OpenMenu(0)"
          class="nav-btn"
          >線上資源</router-link
        >
        <!-- <span href="#" class="nav-btn dropdown" @click="ActveDropDown">
          營業項目
          <ol>
            <li>
              <router-link to="/Features" v-on:click.native="OpenMenu(0)">配方研擬</router-link>
            </li>
            <li>
              <router-link to="/OEM" v-on:click.native="OpenMenu(0)">代工生產</router-link>
            </li>
            <li>
              <router-link to="/BrandPlan" v-on:click.native="OpenMenu(0)">品牌策劃</router-link>
            </li>
          </ol>
        </span>-->
        <router-link
          to="/Contact"
          v-on:click.native="OpenMenu(0)"
          class="nav-btn special contact"
          >聯絡我們</router-link
        >
        <a href="http://104.199.225.155/" class="nav-btn special social"
          >自動化監測平台</a
        >
        <a
          href="javascript:void(0)"
          v-on:click="OpenMenu(0)"
          class="nav-btn special close-btn"
        >
          <i style="margin-right: 10px" class="fas fa-times"></i>關閉
        </a>
      </div>
    </nav>

    <router-view />

    <section id="YCF-contact">
      <div class="row">
        <header>
          <h1 v-html="Contact.title"></h1>
        </header>
        <p v-html="Contact.content"></p>

        <router-link to="/Contact" class="more-btn animation">
          <i class="fas fa-arrow-circle-right"></i>
          {{ Contact.button.title }}
        </router-link>
      </div>
    </section>

    <footer>
      <div class="Footer-top-bar"></div>
      <div class="Logo-box">
        <img
          :src="Footer.img"
          alt="鼎創工程"
          title="鼎創工程"
          class="footer-logo"
        />
        <address>
          {{ Footer.address }}
          <br />
          電話：{{ Footer.phone }}
          <br />
          傳真：{{ Footer.fax }}
          <br />
          {{ Footer.email }}
        </address>
        <div class="footer-contact animation">
          <a :href="Footer.facebook" class="footer-contact-btn">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a :href="'tel:' + Footer.phone" class="footer-contact-btn">
            <i class="fas fa-phone"></i>
          </a>
          <router-link to="/Contact" class="footer-contact-btn">
            <i class="fas fa-envelope"></i>
          </router-link>
        </div>
      </div>
      <hr />
      <div class="copy-right-box">
        <p class="copy-right">
          © 2020 DingChong EngineeringCo.,Ltd. All Rights Reserved..
        </p>
        <p class="web-design">
          網頁設計公司：
          <a href="https://www.yongxin-design.com">泳欣事業有限公司</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<style lang="scss" src="@/assets/CNCFactory.scss"></style>

<script>
export default {
  data() {
    return {
      Contact: {
        title: "認真<span>/</span>專業<span>/</span>負責<span>/</span>守信",
        content:
          "秉持一貫的服務態度與豐富經驗，對於各項工作均全力以赴。不斷的提升自我專業職能，期待能帶給客戶更專業、更周到的各項服務。",
        button: {
          title: "聯絡我們",
          url: "#",
        },
      },
      Footer: {
        img: "/img/YCF_LOGO.png",
        address: "11056台北市信義區富陽街71巷38號",
        phone: "02-2377-0651",
        fax: "02-2377-0652",
        email: "dingchong.tw@gmail.com",
        facebook:
          "https://www.facebook.com/%E9%BC%8E%E5%89%B5%E5%B7%A5%E7%A8%8B%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8-1027315730687789/",
      },
      WindowWidth: 0,
      LogoImg: "/img/LOGO.svg",
    };
  },
  methods: {
    OpenMenu(x) {
      if (x == 0 && window.innerWidth <= 768) {
        document.querySelector(".med-menu-btns").classList.toggle("active");
      }
    },
    ActveDropDown(event) {
      event.toElement.classList.toggle("active");
    },
    Scrollhandler() {
      let windowTop = window.scrollY;
      let windowBottom = windowTop + window.innerHeight;
      let animation = document.querySelectorAll(".animation");
      animation.forEach((ani) => {
        if (ani.offsetTop + ani.offsetHeight / 2 < windowBottom) {
          ani.classList.add("active");
        } else {
          ani.classList.remove("active");
        }
      });
    },
    WindowResize() {
      this.WindowWidth = window.innerWidth;

      if (window.innerWidth <= 768) {
        this.LogoImg = "/img/LOGO_sm.svg";
      } else {
        this.LogoImg = "/img/LOGO.svg";
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.Scrollhandler);
    window.addEventListener("resize", this.WindowResize);
    this.WindowResize();
    this.Scrollhandler();
  },
};
</script>
